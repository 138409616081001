/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
// import ReactSession from 'react-client-session';
import { getPhysician, getPaymentInfo, sendProcessData  } from '../../actions/payActions'
import { number } from "joi";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import NumberFormat from 'react-number-format';



class InitialProcessing extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{Source:"ACT Physician Portal",PaymentMethod:"",CreditCardProcessingFee:0,amount:0},
            card_info:{},
            login:true,
            page:1,
            payment_method:"",
            Physician_info:"",
            Credit_Card_Number:"",
            update_card:false,
            update_ach:false,
            save_card:false,
            transaction_id:"dd",
            payment_response:"",
            Dob:'',
            Ship_date:'',
            card_last_gid:'',
            CreditCard:"",
            api_error:"",
            loading:false,
            Amount:0,
            max_date : new Date('01/01/2100'),
            acc_num:"",
            Payment_Type:"",
            save_ach : "",

        }
    }

    componentDidMount() {            
        var physician_id = localStorage.getItem('physician_id'); 
        if(physician_id){
            this.props.getPhysician(physician_id, this.props.history);    
            this.props.getPaymentInfo(physician_id, this.props.history);
        }     
         
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != ""){
            // console.log('imrn')
            this.setState({'physician_id':physician_id})
           
            
        }
                
    }

    onChange = (e) => {  
        var ID_ = e.target.id
        var VAL = e.target.value
        this.state.formData[e.target.id] = e.target.value; 
        this.setState(formData => ({ID_:VAL }))        
        // console.log('form vlaue=>', this.state.formData);
    }

    checkPrice = (e) =>{
        if(e.target.id=='Adipose'){
            this.state.formData['Tissue'] = e.target.id
            this.state.formData['InitialFee'] = 2500; 
            this.state.formData['amount'] = 2500; 
            this.setState({amount:2500})
            
        }

        if(e.target.id=='Bone_marrow'){
            this.state.formData['Tissue'] = e.target.id
            this.state.formData['InitialFee'] = 5000; 
            this.state.formData['amount'] = 5000; 
            this.setState({amount:5000})

        }
        if(this.state.formData['PaymentMethod'] == 'Credit Card'){
            this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.formData['amount'];

        }else{
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];

        }

        
    }

   

    savecard = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})

            this.state.formData['save_card'] = true
        }

    }

    saveAch = (e) => {
        if(this.state.save_ach){
            this.setState({save_ach:!this.state.save_ach})
            this.state.formData['save_ach'] = !this.state.save_ach
        }
        else{
            this.setState({save_ach:true})

            this.state.formData['save_ach'] = true
        }
    }
    


    setcount = (e) => {
        this.state.page = e.target.id; 
        this.setState({page:e.target.id})
        // console.log('page=>', this.state.page)
        window.scrollTo({top: "10", behavior: 'smooth'});
    }

    datechange1(e){
        // new Date(e);
        this.state.formData['Dob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Dob']);
        this.setState({Dob:e})
    }

    datechange2(e){
        // new Date(e);
        this.state.formData['Ship_date'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Ship_date']);
        this.setState({Ship_date:e})
    }

    

    checkRadio = (e) => {
        if(e.target.id == "credit_card"){
            this.state.formData['CreditCard'] = true; 
            this.setState({CreditCard:e.target.value})
            
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
          this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.formData['amount'];


        }

        if(e.target.id == "shipment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'Check';
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];


            // console.log('CreditCard =>', this.state.CreditCard)
        }

        if(e.target.id == "ACH_payment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];

      


            // console.log('CreditCard =>', this.state.CreditCard)
        }

        this.setState({Payment_Type:e.target.value})
        this.state.formData['Payment_Type'] = e.target.value; 




        
        
        console.log('formData =>', this.state.formData)
        // console.log('target id  =>', e.target.id)
    }

    checkRadio2 = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
        }
        else{
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
            this.setState({update_card:true})
        }
        
        // console.log('update_card =>', this.state.update_card)
    }

    checkAch = (e) => {
        this.setState({update_ach:!this.state.update_ach})
        

        if(this.state.update_ach){
            this.state.formData["Account_Number"] = this.state.card_info['Account_Number']
            this.state.formData["Name_On_Account"] = this.state.card_info['Name_On_Account']
            this.state.formData["ABA_Routing_Number"] = this.state.card_info['ABA_Routing_Number']
        }else{
            this.state.formData["Account_Number"] = ""
            this.state.formData["Name_On_Account"] = ""
            this.state.formData["ABA_Routing_Number"] = ""
            // this.setState({update_ach:true})

        }
        console.log('update_card =>', this.state.update_ach)


    }

    numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps ----------',nextProps); 

        if(nextProps.page_name.pay_info && this.state.update_card==false){
            this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
           
            this.state.formData['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
            this.state.formData['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
           
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            // this.setState({card_info:nextProps.page_name.pay_info})
            this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.state.card_info['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
            this.state.card_info['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
            this.state.card_info['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.card_info['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
            this.state.card_info['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c



            
            if(nextProps.page_name.pay_info.Account_Number__c){
                var acc_num = nextProps.page_name.pay_info.Account_Number__c;
                this.state.acc_num = acc_num.slice(acc_num.length - 4)
                this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            }

            if(nextProps.page_name.pay_info.ABA_Routing_Number__c){
                this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
            }
            
            if(nextProps.page_name.pay_info.Name_on_ACH_Account__c){
                this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            }
            
            if(nextProps.page_name.pay_info.Credit_Card_Number__c){
                var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
                this.state.card_last_gid = nu.slice(nu.length - 4)
            }
            // console.log('credit card => ',this.state.card_last_gid)
            // console.log('form data', this.state.card_info)
          } 
        if(nextProps.page_name.page_name){
            this.setState({loading:false})
            this.state.formData['PhysicianId'] = nextProps.page_name.page_name.Id
            this.state.formData['Id'] = nextProps.page_name.page_name.Physician_ID__c
            this.state.formData['PhysicianLastName'] = nextProps.page_name.page_name.Name 
            
        }

        if(nextProps.page_name.payment_info){
            this.setState({loading:false})
           
            this.setState({transaction_id:nextProps.page_name.payment_info.TransactionId})
            
            if(nextProps.page_name.payment_info.error){
                this.setState({api_error:nextProps.page_name.payment_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            }
            window.scrollTo(0, 0); 
        }

        


    }

    submit = (e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure you want to submit this new sample form?</h1>
                  <p></p>
                  <button onClick={onClose}>No</button>
                  <button
                    onClick={() => {
                        this.onSubmit(e);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            }
          });
        // confirmAlert({
        //   title: 'Confirm to submit',
        //   message: 'Are you sure to do this.',
        //   buttons: [
        //     {
        //       label: 'Yes',
        //       onClick: () => this.onSubmit(e)
        //     },
        //     {
        //       label: 'No',
        //       onClick: () => alert('Click No')
        //     }
        //   ]
        // });
    };
    

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        
        

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Please enter email address";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["Email"] && !validator.isEmail(fields["Email"])) {
            formIsValid = false;
            errors["Email"] = "Invalid email.";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }



        if(!fields["PhoneNumber"]){
            formIsValid = false;
            errors["PhoneNumber"] = "Please enter phone number";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["PhoneNumber"] && isNaN(fields["PhoneNumber"])){
            formIsValid = false;           
            errors["PhoneNumber"] = "Invalid PhoneNumber";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 

        if(!fields["Address"]){
            formIsValid = false;
            errors["Address"] = "Please enter address";
            let element = document.getElementById("Address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["zipcode"]){
            formIsValid = false;
            errors["zipcode"] = "Please enter zipcode";
            let element = document.getElementById("zipcode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(isNaN(fields["zipcode"])){
        //     formIsValid = false;
        //     let postalcode = valid.postalCode(fields["zipcode"]);
        //     // console.log('postalcode',postalcode);
        //     errors["zipcode"] = "Invalid Zip Code";
        //     let element = document.getElementById("zipcode");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        //  }

         if(!fields["City"]){
            formIsValid = false;
            errors["City"] = "Please enter City";
            let element = document.getElementById("City");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["State"]){
            formIsValid = false;
            errors["State"] = "Please enter State";
            let element = document.getElementById("State");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Please enter Country";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["ShippingTrackingNumber"]){
            formIsValid = false;
            errors["ShippingTrackingNumber"] = "Please enter Shipping Tracking Number";
            let element = document.getElementById("ShippingTrackingNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(!fields["Tissue"]){
            formIsValid = false;
            errors["Tissue"] = "Please select sample type";
            let element = document.getElementById("Adipose");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
           
        }

        if(this.state.CreditCard == ""){
            formIsValid = false;
            errors["CreditCard"] = "Please select payment";
            let element = document.getElementById("shipment");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            
        }



        if(!fields["Ship_date"]){
            formIsValid = false;
            errors["Ship_date"] = "Field cannot be empty";
            let element = document.getElementById("Ship_date");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["Ship_date"]){
            const dateFormat = 'MM/DD/YYYY';
            // const dateFormat = 'MM-DD-YYYY';
            const yr = new Date(fields["Ship_date"]).getFullYear();
            console.log('yr',Number(yr));

           
            const toDateFormat = Moment(new Date(fields["Ship_date"])).format(dateFormat);
            let validDate = Moment(toDateFormat, dateFormat, true).isValid();
            console.log('validDate------', validDate);
            console.log('toDateFormat------', toDateFormat);
            console.log('Ship_date------',  fields["Ship_date"]);
           
            if(!validDate || yr >= 2050 ){
                formIsValid = false;
                errors["Ship_date"] = "Invalid date ";
                let element = document.getElementById("Ship_date");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        }

        if(!fields["Dob"]){
            formIsValid = false;
            errors["Dob"] = "Field cannot be empty";
            let element = document.getElementById("Dob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["Dob"]){
            const dateFormat = 'MM/DD/YYYY';
            const yr = new Date(fields["Dob"]).getFullYear();
            const toDateFormat = Moment(new Date(fields["Dob"])).format(dateFormat);
            let validDate = Moment(toDateFormat, dateFormat, true).isValid();
            if(!validDate || yr >= 2050 ){
                formIsValid = false;
                errors["Dob"] = "Invalid date ";
                let element = document.getElementById("Dob");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        }



        if(!fields["FirstName"]){
            formIsValid = false;
            errors["FirstName"] = "Please enter first name";
            let element = document.getElementById("FirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(!fields["LastName"]){
            formIsValid = false;
            errors["LastName"] = "Please enter last name";
            let element = document.getElementById("LastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(this.state.Payment_Type == "CC"){

            if(!fields["Credit_Card_Number"]){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Please enter credit card number";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["Credit_Card_Number"]) {
                let cardvalid = valid.number(fields["Credit_Card_Number"]);
                // console.log('cardvalid',cardvalid);
                if(!cardvalid.isValid){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Invalid credit card number.";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                }
            }

            

            if(!fields["Credit_Card_Holder_First_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                let element = document.getElementById("Credit_Card_Holder_First_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Holder_Last_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Month_of_Expiry"]){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Month_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
            } 
    
          

            if(!fields["Year_of_Expiry"]){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
               

                if(!fields["CVV"]){
                    formIsValid = false;
                    errors["CVV"] = "Cannot be empty";
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }  
                
                if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                    formIsValid = false;
                    errors["CVV"] = "Invalid CVV code"; 
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});   
                    element.className = "form-control error"            
                }

                


        }

        if(this.state.formData['Payment_Type'] == "ACH") {
            
                if(!fields["Account_Number"] || !this.state.card_info['Account_Number']){
                    formIsValid = false;
                    errors["Account_Number"] = "Please enter account number";
                    let element = document.getElementById("Account_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
                if(!fields["Name_On_Account"] || !this.state.card_info['Name_On_Account']){
                    formIsValid = false;
                    errors["Name_On_Account"] = "Field cann't be empty";
                    let element = document.getElementById("Name_On_Account");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
        
                if(!fields["ABA_Routing_Number"] || !this.state.card_info['ABA_Routing_Number']){
                    formIsValid = false;
                    errors["ABA_Routing_Number"] = "Field cann't be empty";
                    let element = document.getElementById("ABA_Routing_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
            

            if(!fields["Account_Number"] || !this.state.card_info['Account_Number']){
                formIsValid = false;
                errors["CreditCard"] = "Please store your ACH Details in the update payment information tab to use ACH Payment Method.";
                let element = document.getElementById("ACH_payment");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }


    
            

        }

        

        

        console.log('this.state.formData  --- ', this.state.formData)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            this.state.formData['amount'] = this.state.formData['amount']+this.state.formData['CreditCardProcessingFee'];

            const newList = this.state.formData;
            console.log("newlist=>", newList);
            this.props.sendProcessData(newList, this.props.history);
            this.setState({loading:true})
            }

    }    

    render() {

        const {formData} = this.state;
        if(!sessionStorage.getItem('physician_id') || sessionStorage.getItem('physician_id')==null) {           
            return <Redirect to="../" />
          } 
        return (
            <MainLayout>
                <Header />
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="../images/spinner.svg" alt="" /></div>
                </div>:""}
                
                <div className="main-content">
                    <Left activeMenu={"initial-processing"}/> 

                    <div className="right-section">
                        <div className="pagesubheader-wrap">
                            <div className="pagesubheader-row">
                                <div className="page-subheader-col-left">
                                    <div className="title-avatar">
                                        <img src="../images/physician-icon.svg" alt="" />
                                    </div>
                                    <div className="title-main">
                                        <span className="title-sub-text">Physician Portal</span>
                                        <h1>Initial Sample Processing</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inner-content-area">  

                        {(this.state.payment_response)?
                        <div>
                            <h5 style={{paddingTop:"10px"}}><i>Please check your email for order confirmation. Please submit the client consent and infectious disease form to complete registration.</i></h5>
                        </div>: 

                        <div>
                        {(this.state.page==1)?                        
                        <div>
                            <h2 className="title-2">Physician Instructions for Patient Initial Sample Processing</h2>
                        <div className="row">
                        <div className="col-md-12">
                            <h4>Adipose Stem Cell Banking Instructions</h4>
                            <ol>
                                <li>Upon receipt of the banking kit, place the gel packs in the freezer for a minimum of one night and place the two conical tubes with 10mL solution in the refrigerator.</li>
                                <li>Split a MINIMUM of 20cc of ADIPOSE TISSUE into the conical tube and label/date the tube with client name appropriately.</li>
                                <li>Place the ice packs and the harvested adipose tissue in the banking kit, have the client sign the Stem Cell Processing and Storage Agreement , complete this Form, including the Dose Retrieval Estimation below and ship OVERNIGHT to the shipping address below for 10:30AM DELIVERY.</li>
                                <li>Physician signs Physician Agreement Attachment to Stem Cell Processing and Storage Agreement (one time only).</li>
                            </ol>
                        </div>
                        {/* <div className="col-md-12">
                            <h4>Bone Marrow Stem Cell Banking Instructions</h4>
                            <ol>
                                <li>Upon receipt of the banking kit, place the gel packs in the freezer for a minimum of one night and place the two conical tubes with 10mL solution in the refrigerator.</li>
                                <li>Split a MINIMUM of 60 GRAMS OF BONE MARROW ASPIRATE into the conical tubes evenly (min. 30 grams in each tube) and label/date the tubes with client name appropriately.</li>
                                <li>Place the ice packs and the harvested Bone Marrow Aspirate in the banking kit, have the client sign the Stem Cell Processing and Storage Agreement, complete this Form, including the Dose Retrieval Estimation below and ship OVERNIGHT to the shipping address below for 10:30AM DELIVERY.</li>
                                <li>Physician signs Physician Agreement Attachment to Stem Cell Processing and Storage Agreement (one time only).</li>
                            </ol>
                        </div> */}
                        <div className="col-md-12">
                            <h4>Shipping Information</h4>
                            <ul>
                                <li>Company: American Cell Technology</li>
                                <li>Address: 13794 NW 4th St, Suite 212, Sunrise, FL 33325 (844-443-CELL)</li>
                                <li>*Physician is responsible for the cost of shipping the banking kit with initial sample to American Cell Technology.</li>                                
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <h4>Payment Information</h4>
                            <ul>
                                <li>Physician provides payment for the $2,500 Adipose or $5,000 Bone Marrow Up-Front Banking Fee by providing credit card information on this Form or by sending a check made out to American Cell Technology with the banking kit.</li>
                                <li>If by check, the check must arrive before or with the banking kit.</li>
                                <li>American Cell Technology must receive payment prior to processing samples or banking stem cells.</li>
                               
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <h4>Important Reminders</h4>
                            <ul>
                                <li>You must NOTIFY American Cell Technology 3 DAYS BEFORE banking shipments by emailing this completed form to info@americancelltechnology.com and include infectious disease script if available, otherwise required no later than 14 days after receipt of the sample.</li>
                                {/* <li>DO NOT SHIP TISSUE SAMPLES ON FRIDAY</li> */}
                                <li>Banking shipments can be sent 7 days a week via OVERNIGHT SHIPPING to American Cell Technology for the 10:30AM DELIVERY</li>
                                <li>If shipping sample on Friday or over the weekend, please select SATURDAY / WEEKEND DELIVERY OPTION.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                        <button type="button" id="2" onClick={this.setcount} className="btn btn-outline-primary mr-2">Next Page</button>
                        </div>
                        </div>
                        </div>
                        :                       
                        <div className="form-wrap glove-form">
                        <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                            <div className="row">
                            <span style={{color: "red"}}>{this.state.api_error}</span>
                            <div className="col-md-12 submission-option-detail">
                                <h2 className="title-2">Physician Information</h2>
                                <div class="submission-detail-sec">                                
                               
                            <div class="row">                          
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Physician ID <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="PhysicianId" className="form-control" placeholder="" value={this.state.formData['Id']} disabled />
                                    <span style={{color: "red"}}>{this.state.errors["PhysicianId"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Physician Last Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="PhysicianLastName" className="form-control" placeholder="" value={this.state.formData['PhysicianLastName']}  disabled />
                                    <span style={{color: "red"}}>{this.state.errors["PhysicianLastName"]}</span>
                                </div>
                                </div>
                            </div>                            
                            </div>
                           



                                </div>
                            
                                <h2 className="title-2">Client Information</h2>
                               
                            <div class="submission-detail-sec">
                                    {/* <h3 class="title-3">Please provide payment information for ACT White Glove Labs:</h3> */}
                                    <div class="row">                          
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">First Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="FirstName" className="form-control" placeholder="" onChange={this.onChange} value={formData.FirstName} />
                                    <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Last Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="LastName" className="form-control" placeholder="" onChange={this.onChange} value={formData.LastName} />
                                    <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                                </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Date of Birth <span className="required">*</span></label>
                                    <div className="field-col">
                                    <DatePicker id="Dob" className="form-control bggray" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.Dob}  onChange={date => this.datechange1(date)} /><br />
                                    {/* <input type="text" id="Dob" className="form-control" placeholder="10 July 1985"  onChange={this.onChange} /> */}
                                    <span style={{color: "red"}}>{this.state.errors["Dob"]}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Ship Date<span className="required">*</span></label>
                                    <div className="field-col">
                                    <DatePicker id="Ship_date" className="form-control bggray" minDate={new Date()} maxDate={this.state.max_date} placeholderText="mm/dd/yyyy" selected={this.state.Ship_date}  onChange={date => this.datechange2(date)} /><br />
                                    {/* <input type="text" id="Dob" className="form-control" placeholder="10 July 1985"  onChange={this.onChange} /> */}
                                    <span style={{color: "red"}}>{this.state.errors["Ship_date"]}</span>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                    <div className="field-col">
                                    <input type="text" id="Email" className="form-control" placeholder="" onChange={this.onChange} value={formData.Email} />
                                    <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
                                    </div>
                                </div>
                                </div>
                            

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Phone Number <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="PhoneNumber" className="form-control" placeholder="" onChange={this.onChange} value={formData.PhoneNumber} />
                                    <span style={{color: "red"}}>{this.state.errors["PhoneNumber"]}</span>
                                </div>
                                </div>
                            </div>
                      
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Address <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Address" className="form-control" onChange={this.onChange} value={formData.Address} placeholder="" />
                                    <span style={{color: "red"}}>{this.state.errors["Address"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">City <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="City" className="form-control" onChange={this.onChange} placeholder="" value={formData.City} />
                                    <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">State <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="State" className="form-control" onChange={this.onChange} placeholder="" value={formData.State} />
                                    <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="zipcode" className="form-control" placeholder="" onChange={this.onChange} value={formData.zipcode} />
                                    <span style={{color: "red"}}>{this.state.errors["zipcode"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Country <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Country" className="form-control" onChange={this.onChange} placeholder="" value={formData.Country} />
                                    <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Shipping Tracking Number <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="ShippingTrackingNumber" className="form-control" onChange={this.onChange} placeholder="" value={formData.ShippingTrackingNumber} />
                                    <span style={{color: "red"}}>{this.state.errors["ShippingTrackingNumber"]}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <h2 className="title-2">Tissue Information</h2>

                        
                        <div class="submission-detail-sec">
                            <h3 class="title-3 mb-4">Please Select Sample Type :</h3>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="Adipose" name="Tissue" value="2500" class="custom-control-input" onChange={this.checkPrice} />
                            <label class="custom-control-label" for="Adipose">Adipose Initial Banking Fee: $2,500</label>
                            </div>
                            <div class="custom-control custom-radio">
                            <input type="radio" id="Bone_marrow" name="Tissue" value="5000" class="custom-control-input" onChange={this.checkPrice} />
                            <label class="custom-control-label" for="Bone_marrow">Bone Marrow Initial Banking Fee: $5,000</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["Tissue"]}</span>
                            <i>*Important Payment Information:
                                    A 3% credit card processing fee will be added to all transactions paid via credit card. To avoid this fee, you may choose to pay via ACH. Thank you for trusting us to safely store your personal stem cells!</i>
                                    <p></p>
                            <br />
                            <h3 class="title-3 mb-4">Please Choose a Payment Method :</h3>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="credit_card" name="CreditCard" class="custom-control-input" value="CC" onClick={this.checkRadio} />
                            <label class="custom-control-label" for="credit_card">Credit Card</label>
                            </div>
                            <div class="custom-control custom-radio">
                            <input type="radio" id="shipment" name="CreditCard" class="custom-control-input" value="Shipment" onClick={this.checkRadio}/>
                            <label class="custom-control-label" for="shipment">Check included in sample shipment</label>
                            </div>  
                            <div class="custom-control custom-radio">
                            <input type="radio" id="ACH_payment" name="CreditCard" class="custom-control-input" value="ACH" onClick={this.checkRadio}/>
                            <label class="custom-control-label" for="ACH_payment">ACH Payment</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                            <br />

                            {(this.state.Payment_Type == "CC")? 
                            <div className="card">
                                        <div className="card-header">
                                        <h4>Current Payment Method Secured For Banking </h4>                                       
                                        </div>
                                        <div className="card-body">
                                        <div className="saved-card-detail">
                                            <div className="row">
                                            <div className="col-md-6">
                                                <label>Card Number</label>
                                                <div className="card-dtl-value">
                                                <img className="card-logo" src="images/master-card.png" alt="" />
                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name on card</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Expiry</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio2}/>
                                            <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                          
                                        </div>

                                        <div className="act-total">
                                            <p>Banking Fee : {this.numberFormat(this.state.amount)}</p>
                                            <p>Processing Fee : {this.numberFormat(this.state.formData['CreditCardProcessingFee'])}</p>
                                           <p> Total: {this.numberFormat( (this.state.amount) + (this.state.formData['CreditCardProcessingFee']))}</p>
                                        </div>

                                       
                                         {(this.state.update_card == true)?
                                        <div className="card-edit-option">
                                            <div className="card-detail-form">
                                            <div className="card-detail-wrap mb-5">
                                                <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Number <span className="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" className="form-control"  onChange={this.onChange} value={formData.Credit_Card_Number} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>CVV <span className="required">*</span> </label>
                                                    <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                    </div>
                                                </div> 
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Expiry Date <span className="required">*</span> </label>
                                                    <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                    <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                    </div>
                                                </div> */}

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" value={formData.Month_of_Expiry}  >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                                <div className="col-md-6 mb-3">
                                                <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                                <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" value={formData.Year_of_Expiry}  >
                                                <option value="">Year</option>                                                
                                                {/* <option value="2023">2023</option> */}
                                                {/* <option value="2024">2024</option> */}
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                </select>
                                                {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                                
                                                <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                                Valid Year required
                                                </div>
                                                </div>

                                                
                                                <div className="col-md-12">
                                                    <div className="card-logo-list">
                                                    <div><img src="images/card-1.svg" alt="" /></div>
                                                    <div><img src="images/card-2.svg" alt="" /></div>
                                                    <div><img src="images/card-3.svg" alt="" /></div>
                                                    <div><img src="images/card-4.svg" alt="" /></div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-12">                                            
                                                    <div className="custom-control custom-checkbox">
                                                    <br />
                                                    <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                                    <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                
                                                    </div>
                                            </div>
                                                </div>
                                            </div>
                                            <div className="card-detail-wrap">
                                                <h5>Billing Information <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Holder's First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} value={formData.Credit_Card_Holder_First_Name}  />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Holder's Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} value={formData.Credit_Card_Holder_Last_Name}  />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Country</label>
                                                    <input type="text" id="Credit_Card_Holder_Country" className="form-control" placeholder onChange={this.onChange} value={formData.Credit_Card_Holder_Country}  />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} value={formData.Credit_Card_Holder_Zip} />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :""}
                                        

                                        </div>
                                    </div>
                            :""}
                          


                            {(this.state.Payment_Type == "ACH")?    
                             <div className="card">
                                <div className="card-header">
                                <h4>ACH Payment Method Secured For Banking </h4>   
                                                                    
                                </div>  
                                <div className="card-body">
                                {(this.state.card_info['ABA_Routing_Number'] ||  this.state.card_info['Account_Number'])?

                                    <div>
                                    <div className="saved-card-detail">                     
                                    <div class="row">          
                                        {/* <h2 className="title-2">ACH Billing Information</h2> */}
                                        <div class="col-md-4">
                                        <label> Name On Account</label>
                                        <div class="card-dtl-value">
                                        <span>{this.state.card_info['Name_On_Account']}</span>
                                        </div>
                                        </div>             
                                        <div class="col-md-4">
                                        <label>Account Number</label>
                                        <div class="card-dtl-value">
                                        <span>Account Number Ending in {this.state.acc_num}
                                        </span>
                                        </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label>ABA Routing Number</label>
                                        <div class="card-dtl-value"> 
                                        <span>{this.state.card_info['ABA_Routing_Number']}
                                        </span>
                                        </div>
                                        </div>
                                    
                                    
                                    </div>
                                    </div>
                                    <br />
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="update_ach" name="update_ach" className="custom-control-input" value="true" onClick={this.checkAch}/>
                                        <label className="custom-control-label" for="update_ach">Use Another ACH </label>
                                        
                                    </div>

                                    <br />
                                    <div lassName="custom-control">
                                        {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'])?
                                        <span><b style={{color: "red"}}>Please store your ACH Details in the <a className="updapte_pay" href="./payment-information">update payment information</a> tab to use ACH Payment Method.</b></span>
                                         
                                        :""}
                                     </div>
                                     </div>
                                :""}

                                   
                                    {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'] || this.state.update_ach)?
                                        <div className="">   
                                            <div className="card-detail-form">
                                            <div class="row">
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Name On Account <span class="required">*</span> </label>
                                                 <input type="text" id="Name_On_Account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_On_Account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Account Number<span class="required">*</span> </label>
                                                 <input type="text" id="Account_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Account_Number"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>ABA Routing Number <span class="required">*</span> </label>
                                                 <input type="text" id="ABA_Routing_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["ABA_Routing_Number"]}</span>
                                             </div>
                                         </div>
                                         <div className="col-md-12">                                            
                                                <div className="custom-control custom-checkbox">
                                                <br />
                                                <input type="checkbox" id="save_ach" name="save_ach" className="custom-control-input" value="true" onClick={this.saveAch}/>
                                                <label className="custom-control-label" for="save_ach">Save this ACH on file for future payments</label>
                                            
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    :""}

                                    <div className="act-total">
                                    <p>Banking Fee : {this.numberFormat(this.state.amount)}</p>
                                    <p>Processing Fee : {this.numberFormat(this.state.formData['CreditCardProcessingFee'])}</p>
                                    <p> Total: {this.numberFormat( (this.state.amount) + (this.state.formData['CreditCardProcessingFee']))}</p>
                                    </div>
                                    
                                </div>
                            </div>
                                :''}        
                        </div> 


                        </div>
                        </div>
                        </div>  

                            <div className="button-row">
                                <button id="1" onClick={this.setcount} type="button" className="btn btn-outline-primary mr-2">Previous Page</button>
                                <button type="button" onClick={this.submit} className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                        </div>
                    }
                        </div>
                        }
                        </div>
                
                        </div>
                        </div>                       
            </MainLayout>
        );
    }
}

InitialProcessing.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    sendProcessData: PropTypes.object.isRequired,
    getPhysician: PropTypes.object.isRequired,
    getPaymentInfo: PropTypes.object.isRequired,
    
};


const mapStateToProps = (state) => ({
    page_name : state.pay,       
    errors: state.errors,
  });


export default connect(mapStateToProps, {sendProcessData,getPhysician,getPaymentInfo  })(InitialProcessing);
