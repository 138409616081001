/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from 'moment'
// import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import "react-datepicker/dist/react-datepicker.css";
// import ReactSession from 'react-client-session';
import { getPatientDoseActivity, getPatientIPActivity,getPendingCellActivity,approvePendingCellActivity,searchPatientIPActivity,SearchDoseActivity } from '../../actions/payActions'
import MUIDataTable from "mui-datatables";
class PatientActivity extends Component {


    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            DoseData:[],
            CellData:[],
            IPData:[],
            formData:{Source:"ACT Physician Portal"},
            login:true,
            loading:true,
            tab:"Dose",
            cellApproval:2,
            checkCloseDate:new Date('2021-12-01')          
        }
    }
    
    componentDidMount() {   
        var search = ""        
        
          
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != null){           
            this.props.getPatientIPActivity(physician_id)
            this.props.getPatientDoseActivity(physician_id,this.props.history); 
            this.setState({'physician_id':physician_id})
            // this.props.getPendingCellActivity('003f000001aEtOfAAK');
            this.props.getPendingCellActivity(physician_id);

            
            // console.log('physician_id => ',physician_id)
        }else{
            return <Redirect to='/home'  />
        }

        console.log('checkCloseDate---------- ', this.state.checkCloseDate.getTime())
                
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps ----------',nextProps); 

        if(nextProps.page_name.dose_info){
            this.state.DoseData = nextProps.page_name.dose_info.listResponse
            console.log('dose data', this.state.DoseData) 
            this.setState({loading:false})
        } 
        
        if(nextProps.page_name.cell_info){
            this.state.CellData = nextProps.page_name.cell_info
            console.log('form data', this.state.CellData) 
            this.setState({loading:false})
            this.setState({cellApproval:2})

        } 

        if(nextProps.page_name.res){
            this.state.IPData = nextProps.page_name.res.listResponse
            // console.log('IPData data', this.state.IPData) 
            this.setState({loading:false})
        } 

        if(nextProps.page_name.cell_approve){
            var physician_id = localStorage.getItem('physician_id');
            console.log('cell approval response >>------- ',nextProps.page_name.cell_approve)
            if(nextProps.page_name.cell_approve == "Success" && this.state.cellApproval=='2'){
            // this.props.getPendingCellActivity(physician_id);
            // this.props.getPendingCellActivity('003f000001aEtOfAAK');
            this.props.getPendingCellActivity(physician_id);

            this.setState({cellApproval:3})




            }
            console.log('physician_id ===>>> ',physician_id);
            console.log('cell approval  ===>>> ',this.state.cellApproval);
            
            // if(this.getState('cellApproval') == 3){
            // this.props.getPendingCellActivity(physician_id);
            //         console.log('hello -----------------------------')
            // }
            this.setState({loading:false})
            // this.setState({cellApproval:false})

            return false;



        }

        // $('#rowTab a:first').tab('show');

    }

    onClick = (e)=> {
        this.setState({loading:true})
        let errors ={}
        errors["Search"] = ""
        this.setState({errors: errors});
        this.setState({tab:"Ip"})
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != null){   
        this.props.getPatientIPActivity(physician_id)
        }
    }

    oncellrequest = (e) => {
        this.setState({loading:true})
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != null){   
        // this.props.getPendingCellActivity('003f000001aEtOfAAK')
        this.props.getPendingCellActivity(physician_id);

        }
    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
        let field_id = e.target.id
        this.setState({field_id:e.target.value})
    }

    orderCell =(e) => {
        const num = e.target.id
        // console.log("order cell ", this.state.DoseData[num])    
        localStorage.setItem('DoseData', JSON.stringify(this.state.DoseData[num]).opportunityRecordId)        
        // this.props.history.push('../stem-cell-request/'+ this.state.DoseData[num].opportunityRecordId)    

    }

    orderIpCell=(e) => {
        const num = e.target.id
        // console.log("order cell ", this.state.IPData[num])    
        localStorage.setItem('DoseData', JSON.stringify(this.state.IPData[num]))
        this.props.history.push('../stem-cell-request/'+ this.state.IPData[num].opportunityRecordId)       

    }

    Reset = (e) => {
        // console.log('reset function call')
        let element = document.getElementById("Search");
        element.value = ""
        this.setState({loading:true})
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id){
            if(this.state.tab == "Dose"){
                // console.log('SearchDoseActivity call')
                this.props.getPatientDoseActivity(physician_id,this.props.history); 
            }else{
                this.props.getPatientIPActivity(physician_id)
            }
        }

    }

    ApproveCell = (e) => {

    }


    searchData = (e) => {
        e.preventDefault();    
        // window.scrollTo(0, 500);
        var physician_id = localStorage.getItem('physician_id');
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        // console.log("hello imran")

        if(!fields["Search"]){
            formIsValid = false;
            errors["Search"] = "Cannot be empty";
            let element = document.getElementById("Search");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        

        if(formIsValid){
            // this.setState({ loading: true });  
            const searchString = {'SearchKey':fields["Search"],'Id':physician_id}
        //    console.log('fields => ',fields["Search"])
           if(this.state.tab == "Dose"){
            // console.log('SearchDoseActivity call')
            this.props.SearchDoseActivity(searchString);
           }else{
            this.props.searchPatientIPActivity(searchString);
           }
            
            this.setState({loading:true})
        }




        // const history = useHistory();
    }

    render() {
        const {DoseData,CellData,checkCloseDate} = this.state
        // var data = []
        const columns = ["Client ID", "Client Name","Annual Storage/Other Fees Paid", "Cell Arrival Date", "Cell Vials Ordered" , "Banked In Date", "Cell Vials Available Immediately","Cell Request Approved By Practitioner","Doctor Name","Tracking Number","Shipment Status","Action"];
        // const columns2 = ["Client ID", "Client Name", "Initial Sample Form","Client Consent Form","Infectious Disease Screening", "Annual Storage Paid", "Banked In Date", "Cell Vials Available Immediately","Action"];
        const columns2 = ["Client ID", "Client Name",  "Banked In Date", "Initial Sample Form","Client Consent Form","Infectious Disease Screening", "Annual Storage/Other Fees Paid", "Cell Vials Available Immediately","Cells Grown Out","Action"];
        const columns3 = ["Client ID","Client Name","Cell Arrival Date","Cell Vials Ordered","Cell Request Approved By Practitioner","Action"]
        const options = {
            selectableRows: false,
            download:false,        
          };

        const orderCell =(e) => {
            const num = e.target.id
            // console.log("order cell ", this.state.DoseData[num])    
            localStorage.setItem('DoseData', JSON.stringify(this.state.DoseData[num]))        
            this.props.history.push('../stem-cell-request/'+ this.state.DoseData[num].opportunityRecordId)     
    
        }

        const orderIpCell=(e) => {
            const num = e.target.id
            // console.log("order cell ", this.state.IPData[num])    
            localStorage.setItem('DoseData', JSON.stringify(this.state.IPData[num]))
            this.props.history.push('../stem-cell-request/'+ this.state.IPData[num].opportunityRecordId)
           
    
        }

        const ApproveCell = (e) => {
            this.setState({loading:true})
            const num = e.target.id;      
        //    console.log(JSON.stringify(this.state.CellData[num].Id))
            // console.log('cell data -----, ', this.state.CellData);
            var physician_id = localStorage.getItem('physician_id');
            var newList = {recordId:this.state.CellData[num].Id,decision:'Approved', physicianId:physician_id }
            this.props.approvePendingCellActivity(newList, this.props.history);          

            
        }

        const DenyCell = (e) => {
            this.setState({loading:true})
            const num = e.target.id;      
            // console.log(JSON.stringify(this.state.CellData[num].opportunityRecordId))
            var physician_id = localStorage.getItem('physician_id');
            var newList = {recordId:this.state.CellData[num].Id,decision:'Denied', physicianId:physician_id}
            this.props.approvePendingCellActivity(newList, this.props.history);          

        }

        var data1 = []
            { this.state.DoseData.map((dt, index) => (
                
                data1.push({"Client ID":dt.clientACTIdHiddenForDose, "Client Name" : dt.clientNameHiddenForDose,"Annual Storage/Other Fees Paid":function (){
                    return (!dt.hasOverDuePayments)?
                        <span className="mdi mdi-check-bold" />
                        : <span style={{color:"red"}} className="mdi mdi-window-close" />
                } ,"Cell Arrival Date":moment(dt.closeDate).format('MM-DD-YYYY'),"Cell Vials Ordered":dt.doseAmount, "Banked In Date":moment(dt.dateBankedin).format('MM-DD-YYYY'), "Cell Vials Available Immediately":dt.longevityVialsAvailable,
                "Cell Request Approved By Practitioner":function(){
                    return ( checkCloseDate.getTime() < new Date(dt.closeDate).getTime())?
                     (dt.cellRequestApprovedByPractitioner)?
                        <span className="mdi mdi-check-bold" />:<span style={{color:"red"}} className="mdi mdi-window-close" />
                        : 'NA'
                },"Doctor Name":dt.doctorName,"Tracking Number":dt.trackingNumber,"Shipment Status":dt.shipmentStatus,"Action":function(){
                    return <div class="action-btns"><button type="button" onClick={orderCell}  id={index} class="btn btn-primary">Order Cells</button></div>;
                    }})
            ))}

        var data2 = []
        { this.state.IPData.map((dt, index) => (
            
            data2.push({"Client ID":dt.clientACTIdHiddenForDose, "Client Name" : dt.clientNameHiddenForDose,"Banked In Date":moment(dt.dateBankedin).format('MM-DD-YYYY'),"Initial Sample Form":function(){
                return (dt.initialProcessingFormCompleted)?
                    <span className="mdi mdi-check-bold" />
                    : <span style={{color:"red"}} className="mdi mdi-window-close" />
            },"Client Consent Form":function (){
                return (dt.clientConsentFormCompleted)?
                    <span className="mdi mdi-check-bold" />
                    : <span style={{color:"red"}} className="mdi mdi-window-close" />
            },"Infectious Disease Screening":function(){
                return (dt.infectiousDiseaseFormCompleted)?
                    <span className="mdi mdi-check-bold" />
                    : <span style={{color:"red"}} className="mdi mdi-window-close" />
            } ,"Annual Storage/Other Fees Paid":function (){
                return (!dt.hasOverDuePayments)?
                    <span className="mdi mdi-check-bold" />
                    : <span style={{color:"red"}} className="mdi mdi-window-close" />
            } , "Cell Vials Available Immediately":dt.longevityVialsAvailable,"Cells Grown Out" :dt.cellsGrownOut ,"Action":function(){
                return <div class="action-btns"><button type="button" onClick={orderIpCell}  id={index} class="btn btn-primary">Order Cells</button></div>;
                }})
        ))}
       
        var data3 = [];

        { this.state.CellData.map((dt, index) => (
            // console.log('Cell approval data ', dt);

            data3.push({"Client ID":dt.Client_ACT_ID_hidden_for_dose__c,"Client Name" : dt.Client__r.First_Name__c+" "+dt.Client__r.Last_Name__c ,"Cell Arrival Date":moment(dt.CloseDate).format('MM-DD-YYYY'),"Cell Vials Ordered":dt.Dose_Amount__c,"Cell Request Approved By Practitioner":function(){
                return (dt.Cell_Request_Approved_By_Practitioner__c)?
                    <span className="mdi mdi-check-bold" />
                    : <span style={{color:"red"}} className="mdi mdi-window-close" />
            },"Action":function(){
                return <div class="action-btns"><button type="button" onClick={ApproveCell}  id={index} class="btn btn-primary">Approve </button> <button type="button" onClick={DenyCell}  id={index} class="btn btn-secondary">Deny  </button></div>;
                }} )
        ))}
           
        //    console.log("data 1 =>" ,data1)
        
       
        if(!sessionStorage.getItem('physician_id') || sessionStorage.getItem('physician_id')==null) {           
            return <Redirect to="../" />
          } 
        return (
            <MainLayout>
                <Header />

                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}

                <div className="main-content">
                    <Left activeMenu={"patient-activity"}/>  
                    <div class="right-section">
                        <div class="pagesubheader-wrap">
                        <div class="pagesubheader-row">
                            <div class="page-subheader-col-left">
                                <div class="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div class="title-main">
                                    <span class="title-sub-text">Physician Portal</span>
                                    <h1>Patient Activity</h1>
                                </div>
                            </div>
                        </div>
                        </div>

                        

                        <div class="inner-content-area">
                        {/* <div class="search-input col-md-6">
                            <i><img style={{marginLeft:"20px"}} src="images/icon_search.png" alt="" /></i>
                            <div> </div>
                            <input id="Search" style={{width:"200px"}} type="search" class="form-control" placeholder="Search Data..." onChange={this.onChange} />
                             <button style={{position:'absolute',top: '0px',left: "227px"}} className="btn btn-primary" onClick={this.searchData}> Search</button>                            
                             <button style={{position:'absolute',top: '0px',left: "308px"}} className="btn btn-secondary" onClick={this.Reset}> Refresh </button>                            
                             <span style={{color: "red"}}>{this.state.errors["Search"]}</span>
                             <br />
                        </div> */}
                        <br />
                        <div class="patient-details-tabs">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                    <a class="nav-link active" onClick={this.onClick} id="pills-related-tab" data-toggle="pill" href="#pills-related" role="tab" aria-controls="pills-related" aria-selected="false">Patient Data</a>
                                </li>
                            <li class="nav-item">
                                <a class="nav-link " id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab" aria-controls="pills-details" aria-selected="true">Cell Retrieval Activity</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " onClick={this.oncellrequest} id="cell-approval-tab" data-toggle="pill" href="#cell-approval" role="tab" aria-controls="cell-approval" aria-selected="true">Pending Cell Request Approvals</a>
                            </li>
                        </ul>
                            <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade " id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                                <div class="table-responsive">
                                <MUIDataTable
                                    title={"Cell Retrieval Activity"}
                                    data={data1}
                                    columns={columns}
                                    options={options}
                                    />                                
                                </div>
                                <p>Note: ACT requires 3 weeks notice on Cell Request Orders if there are not cells available for immediate use.</p>
                            </div>
                            <div class="tab-pane fade show active" id="pills-related" role="tabpanel" aria-labelledby="pills-related-tab">
                                <div class="patient-details-content">
                                <div class="table-responsive">
                                <MUIDataTable
                                    title={"Patient Data"}
                                    data={data2}
                                    columns={columns2}
                                    options={options}
                                    />                                
                                {/* </div> */}

                                    
                                </div>
                                <p>Note: ACT requires 3 weeks notice on Cell Request Orders if there are not cells available for immediate use.</p>
                                </div>
                            </div>

                            <div class="tab-pane fade " id="cell-approval" role="tabpanel" aria-labelledby="cell-approval-tab">
                                <div class="table-responsive">
                                <MUIDataTable
                                    title={"Pending Cell Request Approvals"}
                                    data={data3}
                                    columns={columns3}
                                    options={options}
                                    />                                
                                </div>
                                {/* <p>Note: ACT requires 2 weeks notice on Cell Request Orders if there are not cells available for immediate use.</p> */}
                            </div>

                            </div>
                        </div>
                        </div>
                        </div>
                </div>
            </MainLayout>
        );
    }
}

PatientActivity.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getPhysician:PropTypes.func.isRequired,
    getPatientDoseActivity:PropTypes.func.isRequired,
    getPatientIPActivity:PropTypes.func.isRequired,
    searchPatientIPActivity:PropTypes.func.isRequired,
    SearchDoseActivity:PropTypes.func.isRequired,
    getPendingCellActivity:PropTypes.func.isRequired,
    approvePendingCellActivity:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getPatientDoseActivity,getPendingCellActivity,approvePendingCellActivity,getPatientIPActivity,searchPatientIPActivity,SearchDoseActivity })(PatientActivity);