import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Left from '../layout/Left';


import MainLayout from "../layout/main-layout";
// import Top from "../layout/Top";
import Header from "../layout/Header";

class Congratulations extends Component {
    render() {
        return (
            <MainLayout>
                <Header></Header>
                <div className="main-content">
                    <Left activeMenu={"resources"}/> 

                    <div className="right-section">
                        <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                            <div className="title-avatar">
                                <img src="images/physician-icon.svg" alt="" />
                            </div>
                            <div className="title-main">
                                <span className="title-sub-text">Physician Portal</span>
                                <h1>Resources</h1>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="inner-content-area"> 
                            <ul>
                                <li><a  target="_blank" href="https://www.loom.com/share/7a69b2e481554b5ea7d3795110a2c646?sid=e5c54d8b-fa50-45c9-902e-abe3e69f5323">Portal Demo Video </a></li>
                                <li> <a href="../ACT Banking and Storage Plans_2025.pdf" target="_blank">Annual Storage/Bio-Insurance plan options </a></li>
                                <li> <a target="_blank" href="https://vimeo.com/957259315"> Behind-the-Scenes Video</a></li>
                                <li> <a href="../Mini Lipo Instructions.pdf"  target="_blank">Mini-Lipo Instructions</a> </li>
                                <li><a href="../bloodwork instructions_final.pdf" target="_blank" >Bloodwork Instructions</a></li>
                                <li> <a href="../ACT Process.pdf" target="_blank" > Process Document</a></li>
                                <li><a href="https://americancelltechnology.com/faq/" target="_blank" >FAQ section </a></li>
                                <li>Contact info: Phone: <a href="tel:+844-443-2355">844-443-2355</a> Email: <a href= "mailto: info@americancelltechnology.com"> info@americancelltechnology.com</a></li>
                            </ul>
                        </div>                     


                    </div>

                </div>
            </MainLayout>
        );
    }
}

Congratulations.propTypes = {

};

export default Congratulations;